import LinkListBlock from 'libs/wordpress/content/cmsBlocks/LinkListBlock';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import moduleMargins from 'config/theme/moduleMargins';
import { moduleSettingsProp } from 'utils/proptypes/modules/settingsProps';

const LinkListModule = ({ last = false, settings = {}, ...rest }) => {
    const { background_color: backgroundColor, text_color: textColor } = settings;

    return (
        <MaxWidthWrapper
            includeContentMargins
            as="section"
            backgroundColor={backgroundColor}
            borderBottom={!last ? `1px solid ${colors.black}` : null}
            py={moduleMargins.md}
            paddingTop={moduleMargins.md}
            paddingBottom={['24px', null, '32px', '24px']}
        >
            <LinkListBlock moduleTextColor={textColor} {...rest} />
        </MaxWidthWrapper>
    );
};

LinkListModule.propTypes = {
    last: PropTypes.bool,
    settings: moduleSettingsProp,
};

export default LinkListModule;
