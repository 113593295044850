import React, { useState } from 'react';
import { above, below } from 'utils/mediaqueries';
import { imageProp, mediaQueryProp } from 'utils/proptypes/modules/mediaProps';

import Above from 'components/breakpoints/Above';
import AnimationWrapper from 'components/wrappers/AnimationWrapper';
import ArrowLink from 'components/text/ArrowLink';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundImage from 'components/background/BackgroundImage';
import Below from 'components/breakpoints/Below';
import Item from 'components/contentBlocks/LinkList/Item';
import Link from 'components/base/Link';
import MobileFocusScroll from './MobileFocusScroll';
import PropTypes from 'prop-types';
import Tag from 'components/text/Tag';
import { animations } from 'config/theme/transitions';
import colors from 'config/theme/colors';
import ratios from 'config/theme/ratios';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';
import withAnimation from 'utils/withAnimation';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;

    ${above.md} {
        flex-direction: row;
        align-items: stretch;
        margin: 0 -12px;
    }
`;

const Content = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    order: 2;
    width: calc(100% + 32px);
    margin: 16px -16px 0;

    ${above.sm} {
        width: calc(100% + 48px);
        margin: 24px -24px 0;
    }

    ${above.md} {
        width: 50%;
        order: 1;
        margin: 0;
        padding: 0 16px;
        justify-content: space-between;
    }
`;

const LinksAnimationWrapper = withAnimation(styled('div')`
    &[data-animate='true'] .animate {
        opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
        transform: ${({ animateIn }) =>
            animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[0]})`};
        transition-property: all;
        transition-duration: ${animations.primary.duration}ms;
        transition-timing-function: ${animations.primary.timingFunction};

        ${above.md} {
            transform: ${({ animateIn }) =>
                animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[3]})`};
        }
    }
`);

export const Links = styled('div')`
    display: flex;
    flex-direction: row;

    ${above.md} {
        flex-direction: column;
        align-items: flex-start;
        margin: -12px 0;
    }

    ${below.md} {
        &::before,
        &::after {
            display: block;
            content: '';
            ${responsiveBreakpointsStyle(['16px', '24px'], 'width: {value}; max-width: {value}; flex: 0 0 {value};')}
        }
    }
`;

const ItemWrapper = withAnimation(styled('div')``);

const StyledBackgroundImage = styled(BackgroundImage)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${above.md} {
        width: calc(100% - 24px); /* 24px = 12px column on each side */
    }
`;

const mediaQueries = [
    /* 0  <-  xs */ { w: 375, q: 70 },
    /* xs <-> sm */ { w: 768, q: 70 },
    /* sm <-> md */ { w: 1024, q: 70 },
    /* md <-> lg */ { w: 1440 / 3, q: 70 },
    /* lg <-> xl */ { w: 1920 / 3, q: 70 },
    /* xl ->     */ { w: 1920 / 3, q: 70 },
];

const LinkList = ({
    bottomLink,
    defaultLandscapeBackground,
    defaultPortraitBackground,
    links,
    mediaQuery = mediaQueries,
    tag,
    textColor,
}) => {
    const [activeItem, setActiveItem] = useState({
        landscape: null,
        portrait: null,
        to: null,
    });

    if (!links || links.length === 0) {
        return null;
    }

    // Set image and link depending on active item
    const handleActiveIndex = index => {
        let landscape = null;
        let portrait = null;
        let to = null;

        if (index !== null) {
            landscape = links[index]?.landscapeBackground;
            portrait = links[index]?.portraitBackground;
            to = links[index]?.link?.to;
        }

        if (landscape !== activeItem.landscape || portrait !== activeItem.portrait || to !== activeItem.to) {
            setActiveItem({
                landscape,
                portrait,
                to,
            });
        }
    };

    return (
        <Wrapper color={textColor}>
            <AspectWrapper
                order={[1, null, null, 2]}
                width={['100%', null, null, '50%']}
                px={[null, null, null, '12px']}
                ratio={[ratios.vertical, ratios.square, ratios.verticalWide]}
            >
                <StyledBackgroundImage
                    backgroundPosition={[
                        defaultPortraitBackground.backgroundPosition,
                        null,
                        defaultLandscapeBackground.backgroundPosition,
                        defaultPortraitBackground.backgroundPosition,
                    ]}
                    backgroundSize={[
                        defaultPortraitBackground.backgroundSize,
                        null,
                        defaultLandscapeBackground.backgroundSize,
                        defaultPortraitBackground.backgroundSize,
                    ]}
                    query={mediaQuery}
                    src={[
                        defaultPortraitBackground.url,
                        null,
                        defaultLandscapeBackground.url,
                        defaultPortraitBackground.url,
                    ]}
                />
                {activeItem.landscape && activeItem.portrait && (
                    <Link to={activeItem.to}>
                        <StyledBackgroundImage
                            background={colors.placeholder}
                            src={[activeItem.portrait.url, null, activeItem.landscape.url, activeItem.portrait.url]}
                            query={mediaQuery}
                            backgroundSize={[
                                activeItem.portrait.backgroundSize,
                                null,
                                activeItem.landscape.backgroundSize,
                                activeItem.portrait.backgroundSize,
                            ]}
                            backgroundPosition={[
                                activeItem.portrait.backgroundPosition,
                                null,
                                activeItem.portrait.backgroundSize,
                                activeItem.portrait.backgroundPosition,
                            ]}
                        />
                    </Link>
                )}
            </AspectWrapper>
            <Content>
                <Tag withAnimation display={['none', null, null, 'block']} marginBottom="56px">
                    {tag}
                </Tag>
                <Below
                    breakpoint="md"
                    render={() => <MobileFocusScroll handleActiveIndex={handleActiveIndex} links={links} />}
                />
                <Above
                    breakpoint="md"
                    render={() => (
                        <LinksAnimationWrapper alignItems="flex-start" flexDirection="column" flexWrap="wrap">
                            <Links>
                                {links.map(({ link }, i) => (
                                    <ItemWrapper
                                        className="animate"
                                        key={link.label}
                                        transitionDelay={`${animations.primary.delay * i}ms`}
                                    >
                                        <Item
                                            to={link.to}
                                            label={link.label}
                                            onMouseEnter={() => handleActiveIndex(i)}
                                            onMouseLeave={() => handleActiveIndex(null)}
                                        />
                                    </ItemWrapper>
                                ))}
                            </Links>
                        </LinksAnimationWrapper>
                    )}
                />
                {bottomLink && (
                    <AnimationWrapper>
                        <ArrowLink
                            justifyContent="flex-start"
                            margin={['16px 16px 0', null, '24px 24px 0', '56px 0 0']}
                            to={bottomLink.to}
                        >
                            {bottomLink.label}
                        </ArrowLink>
                    </AnimationWrapper>
                )}
            </Content>
        </Wrapper>
    );
};

LinkList.propTypes = {
    bottomLink: PropTypes.exact({
        label: PropTypes.string,
        to: PropTypes.string,
    }),
    defaultLandscapeBackground: imageProp,
    defaultPortraitBackground: imageProp,
    links: PropTypes.arrayOf(
        PropTypes.exact({
            landscapeBackground: imageProp,
            link: PropTypes.exact({
                label: PropTypes.string,
                to: PropTypes.string,
            }),
            portraitBackground: imageProp,
        })
    ),
    mediaQuery: mediaQueryProp,
    tag: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
};

export default LinkList;
