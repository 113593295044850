import LinkList from 'components/contentBlocks/LinkList';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import transformLink from 'libs/wordpress/utils/transformLink';

// Pure CMS data transformation before we send it to the "real" block!
const LinkListBlock = ({ content = {}, moduleTextColor = '' }) => {
    // Transform background data
    const { background_portrait_landscape: backgroundPortraitLandscape, links, tag, bottom_link: bottomLink } = content;
    const landscapeBackground = backgroundPortraitLandscape.landscape.background_image;
    const portraitBackground = backgroundPortraitLandscape.portrait.background_image;

    // Transform color data
    const ligthTheme = moduleTextColor === 'white';
    const textColor = ligthTheme ? colors.white : colors.black;

    // Transform links data
    const transformedlinks = links.map(({ background_portrait_landscape: backgroundPortraitLandscape, link }) => {
        const landscapeBackground = backgroundPortraitLandscape.landscape.background_image;
        const portraitBackground = backgroundPortraitLandscape.portrait.background_image;

        return {
            link: transformLink(link),
            landscapeBackground: transformBackgroundImage(landscapeBackground),
            portraitBackground: transformBackgroundImage(portraitBackground),
        };
    });

    return (
        <LinkList
            defaultLandscapeBackground={transformBackgroundImage(landscapeBackground)}
            defaultPortraitBackground={transformBackgroundImage(portraitBackground)}
            links={transformedlinks}
            tag={decodeHTMLEntities(tag)}
            textColor={textColor}
            bottomLink={transformLink(bottomLink)}
        />
    );
};

LinkListBlock.propTypes = {
    content: PropTypes.shape({
        'background_portrait_landscape': PropTypes.shape({
            landscape: PropTypes.object,
            portrait: PropTypes.object,
        }).isRequired,
        links: PropTypes.arrayOf(PropTypes.object),
        'top_link': PropTypes.object,
        tag: PropTypes.string,
    }).isRequired,
    moduleTextColor: PropTypes.string,
};

export default LinkListBlock;
