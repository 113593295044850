import React, { useEffect, useRef, useState } from 'react';

import Item from 'components/contentBlocks/LinkList/Item';
import { Links } from './index';
import PropTypes from 'prop-types';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import { imageProp } from 'utils/proptypes/modules/mediaProps';

const MobileFocusScroll = ({ links = [], handleActiveIndex }) => {
    const ref = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [percentageList, setPercentageList] = useState([]);

    useEffect(() => {
        // Get the width of all links inside <Links> as an array
        const nodeWidths = [...ref.current.querySelectorAll('a')].map(node => node.scrollWidth);
        const nodeWidthsTotal = nodeWidths.reduce((total, width) => total + width, 0);

        // Use the total width of all links to calculate the start position of each link as a percentage
        const nodePercenteges = nodeWidths.reduce(
            (list, width, i) => {
                const partPercentage = list[i] + width / nodeWidthsTotal;
                list.push(partPercentage);
                return list;
            },
            [0]
        );

        // Save that percentage list so it can be used in later calculations
        setPercentageList(nodePercenteges);
    }, [ref]);

    return (
        <ScrollWrapper
            displayFadeEffect
            direction="horizontal"
            scrollContentProps={{
                alignItems: 'flex-start',
                flexWrap: 'nowrap',
            }}
            handleScroll={e => {
                let i = 0;
                let listIndex = null;

                // Use the scroll percentage supplied from ScrollWrapper
                // and the percentageList of all links that was calculated before
                // to find what link should be active depending on scroll position
                while (e.scrollPercentageX > percentageList[i]) {
                    if (i !== percentageList.length - 1) {
                        listIndex = i;
                    }
                    i++;
                }

                // Update the activeIndex if needed
                if (activeIndex !== listIndex) {
                    setActiveIndex(listIndex);

                    if (handleActiveIndex) {
                        // Pass the activeIndex to parent component
                        handleActiveIndex(listIndex);
                    }
                }
            }}
        >
            <Links ref={ref}>
                {links.map(({ link }, i) => {
                    return (
                        <Item
                            key={link.label}
                            to={link.to}
                            label={link.label}
                            textDecoration={activeIndex === i ? 'underline' : 'none'}
                        />
                    );
                })}
            </Links>
        </ScrollWrapper>
    );
};

MobileFocusScroll.propTypes = {
    handleActiveIndex: PropTypes.func,
    links: PropTypes.arrayOf(
        PropTypes.exact({
            landscapeBackground: imageProp,
            link: PropTypes.exact({
                label: PropTypes.string,
                to: PropTypes.string,
            }),
            portraitBackground: imageProp,
        })
    ),
};

export default MobileFocusScroll;
