import { above, media } from 'utils/mediaqueries';

import Arrow from 'assets/icons/Arrow';
import BaseLink from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import { timingFunctions as tf } from 'config/theme/transitions';

const Link = styled(BaseLink, { shouldForwardProp: prop => ['duration', 'easing'].indexOf(prop) === -1 })`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: -0.03em;
    transition: ${({ duration, easing }) => `all ${duration}ms ${easing}`};
    overflow: hidden;

    & + a {
        margin-left: 16px;
    }

    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: currentColor;
        transform: scale3d(0, 1, 1);
        transform-origin: 100% 50%;
        transition: ${({ duration, easing }) => `all ${duration}ms ${easing}`};
    }

    .link-arrow {
        display: none;
        margin-left: 24px;
        opacity: 0;
        transition: ${({ duration, easing }) => `all ${duration}ms ${easing}`};

        ${above.md} {
            display: block;
        }
    }

    ${above.sm} {
        font-size: 5.6rem;
        line-height: 1.05;

        & + a {
            margin-left: 24px;
        }
    }

    ${above.md} {
        flex-direction: row;
        line-height: 1;
        padding: 12px 0;

        & + a {
            margin: 0;
        }

        ${media.hover} {
            &:hover::after {
                animation: ${({ duration, easing }) => `textHover ${duration}ms ${easing}`};
                transform: scale3d(1, 1, 1);
            }
            &:hover .link-arrow {
                opacity: 1;
            }
        }
    }

    @keyframes textHover {
        0% {
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }
    }
`;

const Item = ({ duration = 800, easing = tf.easeOutExpo, label, to, ...rest }) => (
    <Link lineHeight="1" id="link-tag" to={to} duration={duration} easing={easing} {...rest}>
        {label}
        <Arrow color="currentColor" height="27px" className="link-arrow" strokeWidth="3" width="13px" />
    </Link>
);

Item.propTypes = {
    duration: PropTypes.number,
    easing: PropTypes.string,
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default Item;
